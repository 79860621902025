import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import "./footer.scss"

const Footer = () => {
	const { site: { siteMetadata } } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						author
					}
				}
			}
		`,
	);

	return (
		<footer className="footer">
			<div className="footer-inner">
				<p>
					© {new Date().getFullYear()}, Built by
          {` `}
					<a href="https://ruben.codes">{siteMetadata.author}</a>
				</p>
			</div>
		</footer>
	);
}

export default Footer;
