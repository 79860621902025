import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import AuthorImage from "./authorImage";

import "./authorInfo.scss";

const AuthorInfo = () => {
	const { site: { siteMetadata } } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						description
						author
					}
				}
			}
		`,
	);

	return (
		<section className="author-info">
			<div className="author-info-left">
				<AuthorImage className="author-info-img" />
			</div>
			<div className="author-info-right">
				<h2 className="author-info-name">
					{siteMetadata.author}
				</h2>
				<p className="author-info-details">
					<i>{siteMetadata.description}</i>
				</p>
			</div>
		</section>
	);
}

export default AuthorInfo;
