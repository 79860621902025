import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import AuthorInfo from "./authorInfo";
import "./header.scss";

const Header = ({ siteTitle }) => (
  <header className="header">
    <div className="header-inner">
      <Link className="header-inner-link" to="/">
        <AuthorInfo />
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
